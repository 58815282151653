import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TGalleryTenderInfo } from '../../../store/gallery/types'
import { GetTime } from '../../../utils/getTime'
import { ROUTES } from '../../../utils/routes'
import { setTimeout_1 } from '../../../utils/helpers'
import { AppStore } from '../../../store/applicationState'
import { GetCurrentTime } from '../../../store/currentTime/actions'
import { LoadTenderPrices, ShowPrices } from '../../../store/gallery/actions'

// import { refresh } from '../../../utils/checkToken'

interface TenderTimerProps {
  tender: TGalleryTenderInfo
}

export const TenderTimer: React.FC<TenderTimerProps> = ({ tender }) => {
  const dispatch = useDispatch()

  const { currentTime, gallery, worthyState } = useSelector((store: AppStore) => store)
  const [timer, setTimer] = useState(0)
  const [timeDiffState, setTimeDiffState] = useState(0)
  const [restartTimer, setRestartTimer] = useState(0)
  const [timerIsOut, setTimerIsOut] = useState(false)
  // const [tokenRefreshed, setTokenRefreshed] = useState(false)

  // hours in milisec (min * sec * milisec) * hours
  const redTime = 60 * 60 * 1000 * 3

  useEffect(() => {
    dispatch(GetCurrentTime.request())
  }, [tender.tender_info.tender_id])

  useEffect(() => {
    let timerId: any
    let intervalId: any

    const finalizedAt = new Date(tender.tender_info.finalized_at)
    // const finalizedAt = new Date('Fri Jun 17 2022 20:41:38 GMT+0300')
    const startAt = new Date(tender.tender_info.start_at)
    // const startAt = new Date('Fri Jun 10 2022 16:15:40 GMT+0300')
    let timeDiff = 0
    if (restartTimer === 0) {
      const serverTime = Math.round(Number(currentTime.data) / 1000000)
      timeDiff = serverTime ? new Date().getTime() - serverTime + 500 : 0
      setTimeDiffState(timeDiff)
    }

    if (finalizedAt.getTime() < new Date().getTime()) window.location.reload()
    if (startAt.getTime() < new Date().getTime() - timeDiff) {
      setTimer(finalizedAt.getTime() - (new Date().getTime() - timeDiff))
      timerId = setTimeout_1(() => {
        window.location.href = `${worthyState.isWorthy ? '/worthy' : ''}${ROUTES.dashboard}`
      }, finalizedAt.getTime() - (new Date().getTime() - timeDiff))
      intervalId = setInterval(() => setTimer(finalizedAt.getTime() - (new Date().getTime() - timeDiff)), 1000)
    } else {
      setTimer(startAt.getTime() - (new Date().getTime() - timeDiff))
      // timerId = setTimeout_1(() => setRestartTimer(1), startAt.getTime() - (new Date().getTime() - timeDiff))
      intervalId = setInterval(() => setTimer(startAt.getTime() - (new Date().getTime() - timeDiff)), 1000)
    }

    return () => {
      clearTimeout(timerId)
      clearInterval(intervalId)
    }
  }, [tender.tender_info.tender_id, currentTime, restartTimer])

  useEffect(() => {
    const serverTime = Math.round(Number(currentTime.data) / 1000000)
    const timeDiff = serverTime ? new Date().getTime() - serverTime : null
    // if (Math.trunc(timer / 1000) < 60 && !tokenRefreshed && timer) {
    //   setTokenRefreshed(true)
    //   refresh()
    // }
    if (Math.trunc(timer / 1000) < 10 && timeDiff && !document.hidden) {
      if (!gallery.data.tenderItems?.some((item) => item.product.asking_price)) {
        dispatch(LoadTenderPrices())
      }
    }
    if (timer < 0 && timeDiff && !document.hidden) {
      setTimerIsOut(true)
      setRestartTimer((prev) => prev + 1)
      dispatch(ShowPrices())
    }
    // if (timer < 0 && !gallery.data.tenderItems?.some((item) => item.product.asking_price) && !document.hidden) {
    //   window.location.reload()
    // }
  }, [timer])

  useEffect(() => {
    if (timerIsOut && gallery.errors && !document.hidden) {
      // eslint-disable-next-line no-console
      console.log('reload url', window.location.href)

      window.location.reload()
    }
  }, [gallery.errors, timerIsOut])

  return (
    <div className='tender-timer-section tender-type text-base tracking-widest flex  space-x-6 font-semibold items-center justify-center'>
      <div className='text-bluegray-600 uppercase hidden lg:flex space-x-3'>
        <div className='tender-category'>
          {tender.tender_info.category_id === 1 && 'DIAMOND TENDER'}
          {tender.tender_info.category_id === 2 && 'JEWELRY TENDER'}
          {tender.tender_info.category_id === 3 && 'WATCH TENDER'}
        </div>

        <div className='sales-cycle text-bluegray-400'>{tender.tender_info.sales_cycle}</div>
      </div>

      <div className=' flex items-end space-x-2 leading-none'>
        <div
          className={`value ${
            timer > redTime || new Date(tender.tender_info.start_at).getTime() < new Date().getTime() - timeDiffState
              ? 'text-teal-500'
              : 'text-red-500'
          }`}
        >
          {GetTime(timer)}
        </div>

        <div className='mode text-bluegray-500 font-normal uppercase text-xs hidden md:block'>
          {new Date(tender.tender_info.start_at).getTime() > new Date().getTime() - timeDiffState
            ? 'TIL START'
            : 'REMAINING'}
        </div>
      </div>
    </div>
  )
}
